import View from './View';
import icons from 'url:../../img/icons.svg'; // parcel 2

class PaginationView extends View {
  _parentElement = document.querySelector('.pagination');

  addHandlerClick(handler) {
    this._parentElement.addEventListener('click', function (e) {
      const btn = e.target.closest('.btn--inline');
      if (btn) {
        const goTo = +btn.dataset.number;
        handler(goTo);
      }
    });
  }

  _generateMarkup() {
    const curPage = this._data.page;
    const numPages = Math.ceil(
      this._data.results.length / this._data.resultsPerPage
    );
    const prevBtn = ` <button data-number=${
      curPage - 1
    }  class="btn--inline pagination__btn--prev">
      <svg class="search__icon">
        <use href="${icons}#icon-arrow-left"></use>
      </svg>
      <span>Page ${curPage - 1}</span>
    </button>`;
    const nextBtn = `
        <button data-number=${
          curPage + 1
        } class="btn--inline pagination__btn--next">
            <span>Page ${curPage + 1}</span>
            <svg class="search__icon">
              <use href="${icons}#icon-arrow-right"></use>
            </svg>
          </button>`;

    //page 1 , and there are other pages
    if (curPage === 1 && numPages > 1) {
      return nextBtn;
    }
    //last page
    if (curPage === numPages && numPages > 1) {
      return prevBtn;
    }
    //other Page
    if (curPage < numPages) {
      return prevBtn + nextBtn;
    }
    //page 1 , and there  are no other pages
    return '';
  }
}

export default new PaginationView();
